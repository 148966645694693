@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&family=Noto+Serif:ital,wght@0,100..900;1,100..900&family=Shrikhand&display=swap');

:root {
  --bg: #E5FFDA;
  --primary: #544541;
  --primary-hover: #72615d;
  --secondary: #DAFEFF;
}

body {
  font-family: 'Inter Tight', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  color: var(--primary);
}

.App {
  display: flex;
  flex-direction: column;
}

header.header, footer.footer {
  background-color: var(--bg);
  width: 100%;
  padding: 2em 0;
  text-align: center;
  display: flex;
  flex-direction: column;
}

h1, h2, h3, h4, h5 {
  font-family: 'Shrikhand', serif;
}

h1.title {
  font-size: 3em;
  margin: 0;
  text-align: center;
  line-height: 1;
}

h1.title > span.kicker {
  display: block;
  font-size: 12px;
  font-family: 'Inter Tight', sans-serif;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 1;
}

h2.desc {
  font-family: 'Noto Serif', serif;
  font-size: 0.9em;
  font-weight: 700;
  margin-top: 2em;
  p {
    margin: 0;
  }
}

p.phone {
  font-weight: 700;
  margin: 0;
  letter-spacing: 1px; 
  line-height: 0.5;
  font-size: 1.2em;
}

div.content {
  align-self: center;
  max-width: 900px;
  margin: 2em 1em;
  h2 {
    font-size: 1.9em;
    text-align: center;
  }
  .menu {
    h3 {
      font-family: 'Inter Tight', sans-serif;
      font-weight: 900;
    }
  }
  h6 {
    font-size: 1.3em;
    font-weight: 800;
    margin: 0;
  }
  .job-announcement {
    text-align: center;
    .button {
      font-weight: 600;
      text-transform: uppercase;
      background-color: var(--primary);
      color: white;
      width: fit-content;
      padding: 0.5em 1em;
      margin: 0 auto;
      border-radius: 5px;
      transition: 0.3s ease-in-out background-color;
      &:hover {
        cursor: pointer;
        background-color: var(--primary-hover);
      }
    }
    .label {
      font-weight: 700;
      margin-bottom: 2em;
    }
  }
}

footer.footer {
  h3 { font-family: 'Noto Serif', serif; font-size: 1em; }
}

.columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.columns > .image {
  grid-column: span 4;
  @media only screen and (min-width: 600px) {
    grid-column: span 1;
  }
}

.columns > .text {
  grid-column: span 4;
  line-height: 1.7;
  @media only screen and (min-width: 600px) {
    grid-column: span 3;
  }
}

section.menu {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
}

.menu-block {
  background-color: var(--secondary);
  padding: 2em;
  grid-column: span 2;
  border-radius: 10px;
  @media only screen and (min-width: 600px) {
    grid-column: span 1;
  }
}

.menu-block > h3 {
  margin: 0;
  font-family: 'Noto Serif', serif;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-bottom: 2px var(--primary) solid;
}

.disclaimer {
  font-size: 0.8em;
  font-style: italic;
}

section.contact {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2em;
}

section.contact > .map-embed {
  grid-column: span 4;
  @media only screen and (min-width: 600px) {
    grid-column: span 1;
  }
}

section.content > .map-embed > iframe {
  height: 100%;
}

section.contact > .text {
  grid-column: span 4;
  @media only screen and (min-width: 600px) {
    grid-column: span 3;
  }
}

section.contact > .text > p {
  margin: 0;
}

section.contact > .text > h4.hours {
  margin: 0.7em 0;
  font-family: 'Noto Serif';
  font-weight: 900;
  font-size: 1.1em;
}

ul.shop-hours {
  list-style-type: none;
  padding: 0; 
}

.announcement {
  background-color: var(--primary);
  color: white;
  font-weight: 700;
  padding: 0.5em 0;
  letter-spacing: 1px;
  text-align: center;
  font-size: 0.7em;
}

p.url {
  margin: 1em 0;
  font-weight: 800;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 0.7em;
}

a {
  font-weight: 700;
  color: var(--primary);
}